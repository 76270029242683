import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Get Dai`}</h1>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <h2>{`Learn How to Get Dai`}</h2>
      <List mdxType="List">
        <p><a parentName="p" {...{
            "href": "/learn/Dai/get-dai/buy-dai/"
          }}>{`Buy Dai`}</a></p>
        <p><a parentName="p" {...{
            "href": "/learn/Dai/get-dai/earn-dai/"
          }}>{`Earn Dai`}</a></p>
        <p><a parentName="p" {...{
            "href": "learn/Dai/get-dai/borrow-dai/"
          }}>{`Borrow Dai`}</a></p>
      </List>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      